import React, { Component } from 'react';
import Imgone from '../assets/hero.svg';

export default class hero extends Component {
    render() {
        return (
            <section class="bg-gray-900 text-gray-100 ">
            <div class="container mx-auto flex flex-col justify-center p-6 sm:py-12 lg:py-24 lg:flex-row lg:justify-between">
                <div class="flex flex-col justify-center p-6 text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
                    <h1 class="text-5xl font-bold leading-none sm:text-6xl">Ritmo para seu
                        <span class="text-blue-accent-200"> sucesso.</span>
                    </h1>
                    <p class="mt-6 mb-8 text-lg sm:mb-12">Tem uma idea na sua mente, 
                    <br class="hidden md:inline lg:hidden"/> só nos contatar, fazemos seu<span class="text-blue-accent-200"> sonho realidade.</span>
                    </p>
                    <div class="flex flex-col space-y-4 sm:items-center sm:justify-center sm:flex-row sm:space-y-0 sm:space-x-4 lg:justify-start">
                        
                    </div>
                </div>
                
                <div class="flex items-center justify-center p-6 mt-8 lg:mt-0 h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128">
                    <img src={Imgone} alt="" class="object-contain h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128"/>
                </div>
            </div>
        </section>

          
        )
    }
}
