import React, { Component } from 'react';
import Imgone from '../assets/equipe.svg';
import Imgtwo from '../assets/codigo.svg';
import Imgthree from '../assets/devices.svg';

export default class features extends Component {
    render() {
        return (
<section class="p-4 lg:p-8 bg-gray-900 text-gray-100">
	<div class="container mx-auto space-y-12">
		<div class="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row">
			<div class="flex items-center justify-center flex-1 rounded bg-blue-accent-200">
				<img src={Imgone} alt="" class="p-8 h-60"/>
			</div>
			<div class="flex flex-col justify-center flex-1 px-6 py-12 bg-gray-900">
				<span class="text-3xs uppercase text-gray-400">Trabalho em equipe!</span>
				<h2 class="text-3xl font-bold">Nosso time trabalha para fazer seu sonho realidade!</h2>
				<p class="my-6 text-gray-400">O primeiro passo para esse sonho surgir está em nós contatar.</p>
				
			</div>
		</div>
		<div class="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row-reverse">
			<div class="flex items-center justify-center flex-1 rounded bg-blue-accent-200">
				<img src={Imgtwo} alt="" class="p-8 h-60"/>
			</div>
			<div class="flex flex-col justify-center flex-1 px-6 py-12 bg-gray-900">
				<span class="text-3xs uppercase text-gray-400">Código Amigável.</span>
				<h2 class="text-3xl font-bold">Trabalhamos com código 100% amigável para todos entender nosso trabalho.</h2>
				<p class="my-6 text-gray-400">Sera testigo de cada passo realizado para a criação da sua ideia.</p>
				
			</div>
		</div>
		<div class="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row">
			<div class="flex items-center justify-center flex-1 rounded bg-blue-accent-200">
				<img src={Imgthree} alt="" class="p-8 h-60"/>
			</div>
			<div class="flex flex-col justify-center flex-1 px-6 py-12 bg-gray-900">
				<span class="text-3xs uppercase text-gray-400">Design Responsivo.</span>
				<h2 class="text-3xl font-bold">Um aplicativo 100% com qualidade para qualquer dispositivo.</h2>
				<p class="my-6 text-gray-400">O produto terminado será algo que vai surprende-lo.</p>
				
			</div>
		</div>
	</div>
</section>
        )
    }
}
