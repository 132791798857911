import React, { Fragment } from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './components/navbar3';
import Hero from './components/hero';
import Footer from './components/footer';
import Vantagens from './components/features';

function App() {
  return (
    <Router>
    <Fragment>
      <Navbar/>
      <Hero/>
      <Vantagens/>
      <Footer/>

    </Fragment>
    
    </Router>
    
  );
}

export default App;
